import { IWorkspaceState } from "./states"
import { Module } from "vuex"
import { IWorkspace } from "@/types/api-orisis/interfaces/Account/IWorkspace"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IWorkspaceState = {
    workspacesList: Array<IWorkspace>(),
    workspace: <IWorkspace>{},
    isLoadingWorkspace: false,
    isCreatingWorkspace: false,
    isUpdatingWorkspace: false,
    isDeletingWorkspace: false,
    requestStateWorkspace: <IRequestState>{},
    workspaceSelected: <IWorkspace>{}
}

export const WorkspaceModule: Module<IWorkspaceState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}