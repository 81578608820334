import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IJob } from "@/types/api-orisis/interfaces/Directory/IJob";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { IJobState } from "./states";

export const getters: GetterTree<IJobState, RootState> = {
    jobsList: (state): IJob[] => state.jobsList,
    job     : (state): IJob => state.job,
    
    // COMMON
    isLoadingJobsList   : (state): boolean => state.isLoadingJobsList,
    isLoadingJob   : (state): boolean => state.isLoadingJob,
    isCreatingJob  : (state): boolean => state.isCreatingJob,
    isUpdatingJob  : (state): boolean => state.isUpdatingJob,
    isDeletingJob  : (state): boolean => state.isDeletingJob,
    requestStateJob: (state): IRequestState => state.requestStateJob
}