
import { IPlanningView } from "@/types/api-orisis/interfaces/Planning/IPlanningViews";
import { GetterTree } from "vuex";
import { PlanningViewState } from "./types";
import { IPlanningLine } from '@/types/api-orisis/interfaces/Planning/PlanningLine';

export const getters : GetterTree<PlanningViewState, {}> = {
    isSidebarPlanningViewSettingsOpen: (state): Boolean => state.isSidebarPlanningViewSettingsOpen,
    planningView                     : (state): IPlanningView => state.planningView,
    planningViewsList                : (state): IPlanningView[] => state.planningViewsList,
    planningViewPayload              : (state): IPlanningView => state.planningViewPayload,
    planningLinesCollaborator        : (state): IPlanningLine[] => state.planningLinesCollaborator,
    planningLinesAffair              : (state): IPlanningLine[] => state.planningLinesAffair,
    planningLinesFreeLine            : (state): IPlanningLine[] => state.planningLinesFreeLine,
    isLoadingPlanningView            : (state): Boolean => state.isLoadingPlanningView,
    isLoadingPlanningViewsList       : (state): Boolean => state.isLoadingPlanningViewsList,
    isPlanningViewCreating           : (state): Boolean => state.isCreating,
    isPlanningViewUpdating           : (state): Boolean => state.isUpdating,
    createdDataPlanningView          : (state): IPlanningView => state.createdData,
    updatedDataPlanningView          : (state): IPlanningView => state.updatedData,
    isPlanningViewDeleting           : (state): Boolean => state.isDeleting,
    deletedDataPlanningView          : (state): IPlanningView => state.deletedData,
}  