import { IContract } from '@/types/api-orisis/interfaces/Directory/IContract'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { ContractMutations } from './mutations'
import { IContractState } from './states'

export const actions: ActionTree<IContractState, RootState> = {
  /**
   * It fetches the list of contracts from the API and stores it in the state.
   */
  async fetchContractsList ({ commit, rootGetters }): Promise<void> {
    commit(ContractMutations.SET_IS_LOADING_CONTRACTS_LIST, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Contract/GetContract',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected
          }
        }
      )
      .then(res => {
        commit(ContractMutations.SET_CONTRACTS_LIST, res.data)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_LOADING_CONTRACTS_LIST, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_LOADING_CONTRACTS_LIST, false)
      })
  },

  /**
   * Fetch a contract from the API
   * @param  - contractId: The id of the contract you want to fetch.
   * @param {string} contractId - The id of the contract you want to fetch.
   */
  async fetchContract (
    { commit, rootGetters },
    contractId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      commit(ContractMutations.SET_IS_LOADING_CONTRACT, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Contract/GetContractById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            contractId: contractId
          }
        }
      )
      .then(res => {
        commit(ContractMutations.SET_CONTRACT, res.data)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, false)
        resolve( res.data)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_LOADING_CONTRACT, false)
      })
    })
  },

  /**
   * Create a contract and add it to the contracts list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IContract} contract - The contract object that will be sent to the API.
   */
  async createContract (
    { commit, rootGetters },
    contract: IContract
  ): Promise<void> {
    commit(ContractMutations.SET_IS_CREATING_CONTRACT, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Contract/CreateContract',
          contract,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        rootGetters.contractsList.unshift(res.data.data)
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_CREATING_CONTRACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_CREATING_CONTRACT, false)
      })
  },

  /**
   * Update a contract
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IContract} contract - The contract object that will be updated.
   */
  async updateContract (
    { commit, rootGetters },
    contract: IContract
  ): Promise<void> {
    commit(ContractMutations.SET_IS_UPDATING_CONTRACT, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Contract/UpdateContract',
          contract,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        rootGetters.contractsList.splice(
          rootGetters.contractsList.findIndex(
            elem => elem.id == contract.id
          ),
          1,
          contract
        )
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_UPDATING_CONTRACT, false)
      })
  },

  /**
   * Delete a contract from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} contractId - The id of the contract to delete.
   */
  async deleteContract (
    { commit, rootGetters },
    contractId: string
  ): Promise<void> {
    commit(ContractMutations.SET_IS_DELETING_CONTRACT, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Contract/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            contractId: contractId
          }
        }
      )
      .then(res => {
        rootGetters.contractsList.splice(
          rootGetters.contractsList.findIndex(
            elem => elem.id == contractId
          ),
          1
        )
        commit(
          ContractMutations.SET_CONTRACTS_LIST,
          rootGetters.contractsList
        )
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, res)
        commit(ContractMutations.SET_IS_DELETING_CONTRACT, false)
      })
      .catch(err => {
        console.error(err)
        commit(ContractMutations.SET_REQUEST_STATE_CONTRACT, err)
        commit(ContractMutations.SET_IS_DELETING_CONTRACT, false)
      })
  }
}
