import { ProgrammedSmsRequestDto } from '@/../Api'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { SmsMutations } from './mutations'
import { ISmsState } from './states'

export const actions: ActionTree<ISmsState, RootState> = {
  /**
   * It fetches the list of sms from the API and stores it in the state.
   */
  async sendSms (
    { commit, rootGetters },
    sms: ProgrammedSmsRequestDto
  ): Promise<void> {
    commit(SmsMutations.SET_IS_LOADING_SMS, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS + 'Plannings/Sms/ProgrammedSms',
        { ...sms, workspaceId: rootGetters.workspaceSelected },
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        commit(SmsMutations.SET_IS_LOADING_SMS, false)
        commit(SmsMutations.SET_REQUEST_STATE_SMS, res)
      })
      .catch(err => {
        console.error(err)
        commit(SmsMutations.SET_REQUEST_STATE_SMS, err)
        commit(SmsMutations.SET_IS_LOADING_SMS, false)
      })
  }
}
