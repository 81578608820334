import { RootState } from '@/store/types';
import {Module} from 'vuex';
import { PlanningViewState } from './states';
import { IPlanningView } from '@/types/api-orisis/interfaces/Planning/IPlanningViews'
import { IPlanningLine } from '@/types/api-orisis/interfaces/Planning/PlanningLine'
import { getters } from './getters';
import { mutations } from './mutations';
import { actions } from './actions';

const state:PlanningViewState = {
    isSidebarPlanningViewSettingsOpen: false,
    planningView: <IPlanningView>{},
    planningViewsList: Array<IPlanningView>(),
    planningViewPayload: <IPlanningView>{},
    isLoading: false,
    isLoadingPlanningViewsList: false,
    isLoadingDetail: false,
    isCreating: false,
    createdData: <IPlanningView>{}, 
    isUpdating: false,
    updatedData: <IPlanningView>{},
    isDeleting: false,
    deletedData: <IPlanningView>{},
    planningLinesCollaborator: Array<IPlanningLine>(),
    planningLinesAffair: Array<IPlanningLine>(),
    planningLinesFreeLine: Array<IPlanningLine>()
  }

export const PlanningViewModule: Module<PlanningViewState, RootState> = {
    state,
    getters,
    mutations, 
    actions
}