import { ICompanyTypeState } from "./states";
import { MutationTree } from "vuex";
import { CompanyType } from "@/;;:CompanyType";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";




export enum CompanyTypeMutations {
  SET_COMPANIES_TYPES_LIST = "SET_COMPANIES_TYPES_LIST",
  SET_COMPANY_TYPE = "SET_COMPANY_TYPE",

  // COMON
  SET_IS_LOADING_COMPANY_TYPE = "SET_IS_LOADING_COMPANY_TYPE",
  SET_IS_CREATING_COMPANY_TYPE = "SET_IS_CREATING_COMPANY_TYPE",
  SET_IS_UPDATING_COMPANY_TYPE = "SET_IS_UPDATING_COMPANY_TYPE",
  SET_IS_DELETING_COMPANY_TYPE = "SET_IS_DELETING_COMPANY_TYPE",
  SET_REQUEST_STATE_COMPANY_TYPE = "SET_REQUEST_STATE_COMPANY_TYPE",
  SET_COMPANY_TYPE_LIST = "SET_COMPANY_TYPE_LIST"
}

  export const mutations: MutationTree<ICompanyTypeState> = {
    [CompanyTypeMutations.SET_COMPANIES_TYPES_LIST]: (state, payload: ICompanyType[]) => {
        state.companiesTypesList = payload;
    },
    [CompanyTypeMutations.SET_COMPANY_TYPE]: (state, payload: ICompanyType) => {
        state.companyType = payload;
    },


    //COMMON
    [CompanyTypeMutations.SET_IS_LOADING_COMPANY_TYPE]: (state, payload: boolean) => {
        state.isLoadingCompanyType = payload;
    },
    [CompanyTypeMutations.SET_IS_CREATING_COMPANY_TYPE]: (state, payload: boolean) => {
        state.isCreatingCompanyType = payload;
    },
    [CompanyTypeMutations.SET_IS_UPDATING_COMPANY_TYPE]: (state, payload: boolean) => {
        state.isUpdatingCompanyType = payload;
    },
    [CompanyTypeMutations.SET_IS_DELETING_COMPANY_TYPE]: (state, payload: boolean) => {
        state.isDeletingCompanyType = payload;
    },
    [CompanyTypeMutations.SET_REQUEST_STATE_COMPANY_TYPE]: (state, payload: IRequestState) => {
        state.requestStateCompanyType = payload;
    },
}

