import { IEventType } from '@/types/api-orisis/interfaces/Planning/IEventType'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { EventTypeMutations } from './mutations'
import { IEventTypeState } from './states'

export const actions: ActionTree<IEventTypeState, RootState> = {
  /**
   * It fetches the list of eventTypes from the API and stores it in the state.
   */
  async fetchEventTypesList ({ commit, rootGetters }): Promise<void> {
  return new Promise<void>((resolve, reject) => { 
    commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/EventType/GetEventTypeByWorkspaceId',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected
          }
        }
      )
      .then(res => {
        commit(EventTypeMutations.SET_EVENT_TYPES_LIST, res.data)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST, false)
        resolve(res.data)
        return res.data
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST, false)
      })
  })
  },
  async fetchEventTypesListArchived ({ commit, rootGetters }): Promise<void> {
  return new Promise<void>((resolve, reject) => { 
    commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/EventType/GetEventTypeArchivedByWorkspaceId',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected
          }
        }
      )
      .then(res => {
        commit(EventTypeMutations.SET_EVENT_TYPES_LIST_ARCHIVED, res.data)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED, false)
        resolve(res.data)
        return res.data
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE_LIST_ARCHIVED, false)
      })
  })
  },

  /**
   * Fetch a eventType from the API
   * @param  - eventTypeId: The id of the eventType you want to fetch.
   * @param {string} eventTypeId - The id of the eventType you want to fetch.
   */
  async fetchEventType (
    { commit, rootGetters },
    eventTypeId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
      commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/EventType/GetEventTypeById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            eventTypeId: eventTypeId
          }
        }
      )
      .then(res => {
        commit(EventTypeMutations.SET_EVENT_TYPE, res.data)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false)
      })
    })
  },
  async fetchEventTypeArchived (
    { commit, rootGetters },
    eventTypeId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) =>{
      commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/EventType/GetEventTypeArchivedById',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            eventTypeId: eventTypeId
          }
        }
      )
      .then(res => {
        commit(EventTypeMutations.SET_EVENT_TYPE, res.data)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false)
        resolve(res.data)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_LOADING_EVENT_TYPE, false)
      })
    })
  },

  /**
   * Create a eventType and add it to the eventTypes list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IEventType} eventType - The eventType object that will be sent to the API.
   */
  async createEventType (
    { commit, rootGetters },
    eventType: IEventType
  ): Promise<void> {
    commit(EventTypeMutations.SET_IS_CREATING_EVENT_TYPE, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/EventType/CreateEventType',
        eventType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        rootGetters.eventTypesList.unshift(res.data.data)
        commit(
          EventTypeMutations.SET_EVENT_TYPES_LIST,
          rootGetters.eventTypesList
        )
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_CREATING_EVENT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_CREATING_EVENT_TYPE, false)
      })
  },

  /**
   * Update a eventType
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IEventType} eventType - The eventType object that will be updated.
   */
  async updateEventType (
    { commit, rootGetters },
    eventType: IEventType
  ): Promise<void> {
    commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/EventType/UpdateEventType',
        eventType,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        commit(
          rootGetters.eventTypesList.splice(
            rootGetters.eventTypesList.findIndex(
              elem => elem.id == eventType.id
            ),
            1,
            eventType
          ),
          EventTypeMutations.SET_EVENT_TYPES_LIST,
          rootGetters.eventTypesList
        )
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false)
      })
  },

  /**
   * Delete a eventType from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} eventTypeId - The id of the eventType to delete.
   */
  async deleteEventType (
    { commit, rootGetters },
    eventTypeId: string
  ): Promise<void> {
    commit(EventTypeMutations.SET_IS_DELETING_EVENT_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Plannings/EventType/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            eventTypeId: eventTypeId
          }
        }
      )
      .then(res => {
        rootGetters.eventTypesListArchived.splice(
          rootGetters.eventTypesListArchived.findIndex(
            elem => elem.id == eventTypeId
          ),
          1
        )
        commit(
          EventTypeMutations.SET_EVENT_TYPES_LIST,
          rootGetters.eventTypesList
        )
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_DELETING_EVENT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_DELETING_EVENT_TYPE, false)
      })
  },

  /**
   * Delete a eventType from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} eventTypeId - The id of the eventType to delete.
   */
   async archiveEventType (
    { commit, rootGetters },
    eventTypeId: string
  ): Promise<void> {
    commit(EventTypeMutations.SET_IS_ARCHIVING_EVENT_TYPE, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Directory/EventType/Archive',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          data:[eventTypeId]
        }
      )
      .then(res => {
        rootGetters.eventTypesListArchived.unshift(rootGetters.eventTypesList.find(elem => elem.id == eventTypeId ))
        rootGetters.eventTypesList.splice(
          rootGetters.eventTypesList.findIndex(
            elem => elem.id == eventTypeId
          ),
          1
        )
        commit(
          EventTypeMutations.SET_EVENT_TYPES_LIST,
          rootGetters.eventTypesList
        )
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_ARCHIVING_EVENT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_ARCHIVING_EVENT_TYPE, false)
      })
   },

   async restoreEventType (
    { commit, rootGetters },
    eventTypeId: string
  ): Promise<void> {
    commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS + 'Directory/EventType/Restore',
        [eventTypeId],
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        rootGetters.eventTypesList.unshift(rootGetters.eventTypesListArchived.find(elem => elem.id == eventTypeId ))
        rootGetters.eventTypesListArchived.splice(
          rootGetters.eventTypesListArchived.findIndex(
            elem => elem.id == eventTypeId
          ),
          1
        )
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, res)
        commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false)
      })
      .catch(err => {
        console.error(err)
        commit(EventTypeMutations.SET_REQUEST_STATE_EVENT_TYPE, err)
        commit(EventTypeMutations.SET_IS_UPDATING_EVENT_TYPE, false)
      })
   }
}
