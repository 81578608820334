import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { IWorkspace } from '@/types/api-orisis/interfaces/Account/IWorkspace'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IWorkspaceState } from './states'


export const getters: GetterTree<IWorkspaceState, RootState> = {
  workspacesList: (state): IWorkspace[] => state.workspacesList,
  workspace     : (state): IWorkspace => state.workspace,
  workspaceSelected: (state): IWorkspace => state.workspaceSelected,

  // COMMON
  isLoadingWorkspace   : (state): boolean => state.isLoadingWorkspace,
  isCreatingWorkspace  : (state): boolean => state.isCreatingWorkspace,
  isUpdatingWorkspace  : (state): boolean => state.isUpdatingWorkspace,
  isDeletingWorkspace  : (state): boolean => state.isDeletingWorkspace,
  requestStateWorkspace: (state): IRequestState => state.requestStateWorkspace
}
