import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ICompany } from "@/types/api-orisis/interfaces/Directory/ICompany";
import RootState from "@/store/modules/RootState"
import { ICompanyState } from "./states";
import { Module } from "vuex"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"

export const state: ICompanyState = {
    companiesList      : Array<ICompany>(),
    companiesListArchived      : Array<ICompany>(),
    company            : <ICompany>{},
    isLoadingCompaniesList   : false,
    isLoadingCompaniesListArchived : false,
    isLoadingCompany   : false,
    isCreatingCompany  : false,
    isUpdatingCompany  : false,
    isDeletingCompany  : false,
    requestStateCompany: <IRequestState>{},
    isOpenSidebarCompany : false,
    isArchivingCompany : false,
}

export const CompanyModule: Module<ICompanyState, RootState> = {
  state, 
  getters,
  mutations,
  actions
}