import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { IContract } from "@/types/api-orisis/interfaces/Directory/IContract";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import {  IContractState } from "./states";

export const getters: GetterTree<IContractState, RootState> = {
    contractsList: (state): IContract[] => state.contractsList,
    contract     : (state): IContract => state.contract,
    
    // COMMON
    isLoadingContractsList   : (state): boolean => state.isLoadingContractsList,
    isLoadingContract   : (state): boolean => state.isLoadingContract,
    isCreatingContract  : (state): boolean => state.isCreatingContract,
    isUpdatingContract  : (state): boolean => state.isUpdatingContract,
    isDeletingContract  : (state): boolean => state.isDeletingContract,
    requestStateContract: (state): IRequestState => state.requestStateContract
}