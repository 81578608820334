import { IRequestState } from '@/types/api-orisis/interfaces/Common/IRequestState'
import { IAffair } from '@/types/api-orisis/interfaces/Directory/IAffair'
import { GetterTree } from 'vuex'
import RootState from '../../RootState'
import { IAffairState } from './states'


export const getters: GetterTree<IAffairState, RootState> = {
  affairsList: (state): IAffair[] => state.affairsList,
  affairsListArchived: (state): IAffair[] => state.affairsListArchived,
  affair     : (state): IAffair => state.affair,

  // COMMON
  isLoadingAffairsList   : (state): boolean => state.isLoadingAffairsList,
  isLoadingAffairsListArchived   : (state): boolean => state.isLoadingAffairsListArchived,
  isLoadingAffair   : (state): boolean => state.isLoadingAffair,
  isCreatingAffair  : (state): boolean => state.isCreatingAffair,
  isUpdatingAffair  : (state): boolean => state.isUpdatingAffair,
  isDeletingAffair  : (state): boolean => state.isDeletingAffair,
  isArchivingAffair  : (state): boolean => state.isArchivingAffair,
  requestStateAffair: (state): IRequestState => state.requestStateAffair,
  isOpenSidebarAffair : (state): boolean => state.isOpenSidebarAffair,
}



