import Vue from 'vue'
import VueRouter from 'vue-router'

// RouterTabRoutes
import { RouterTabRoutes } from 'vue-router-tab'

// layout component
import Frame from '@/layouts/Frame.vue'


import "material-icons/iconfont/material-icons.css"; //Material Icons

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      // parent component must contain <router-tab>
      component: Frame,
      // routes that serve as tab contents
      children: [
        // integrate RouterTabRoutes to support iframe tabs
        ...RouterTabRoutes,
        {
          path: '/',
          name: 'home',
          component: () => import('@/views/Home.vue'),
          meta: {
            pageTitle: 'Accueil',
            breadcrumb: [
              {
                text: 'Accueil',
                active: false,
              },
            ],
            title: 'Accueil', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Tableau de bord principal',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/account',
          name: 'account',
          component: () => import('@/views/account/users/Account.vue'),
          meta: {
            pageTitle: 'Mon compte',
            breadcrumb: [
              {
                text: 'Mon compte',
                active: true,
              },
            ],
            title: 'Mon compte', 
            icon: 'icon-user', 
            tips: 'Gestion de mon compte ORISIS',
            key: 'path', 
            closable: true 
          },
        },

        /* *** Gestion des Users *** */
        {
          path: '/workspaces',
          name: 'workspaces',
          component: () => import('@/views/account/workspaces/AdminWorkspaces.vue'),
          meta: {
            pageTitle: 'Espaces de travail',
            breadcrumb: [
              {
                text: 'Mes espaces de travail',
                active: true,
              },
            ],
            title: 'Espace de travail', // tab title
            icon: 'icon-home', // tab icon, optional
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Mes espaces de travail', // tab tooltip, optional. defaults to `meta.title`
            key: 'path', // tab cache rule, optional
            closable: true // is tab closable, defaults to `true`
          },
        },
        {
          path: '/users',
          name: 'users',
          component: () => import('@/views/directory/user/Users.vue'),
          meta: {
            pageTitle: 'Utilisateurs',
            breadcrumb: [
              {
                text: 'Utilisateurs',
                active: true,
              },
            ],
            title: 'Utilisateurs', 
            icon: 'icon-users', 
            tips: 'Liste des utilisateurs',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-user',
          name: 'new-user',
          component: () => import('@/views/directory/user/EditUser.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouvel utilisateur',
            breadcrumb: [
              {
                text: 'Utilisateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouvel utilisateur', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau utilisateur',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/edit-user/:id',
          name: 'edit-user',
          component: () => import('@/views/directory/user/EditUser.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche utilisateur',
            breadcrumb: [
              {
                text: 'Utilisateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un utilisateur', 
            icon: 'icon-home', 
            tips: 'Editer un utilisateur',
            key: 'path', 
            closable: true 
          },
        },

        /* *** Gestion des Collaborateurs *** */
        {
          path: '/collaborators',
          name: 'collaborators',
          component: () => import('@/views/directory/collaborator/Collaborators.vue'),
          meta: {
            pageTitle: 'Collaborateurs',
            breadcrumb: [
              {
                text: 'Collaborateurs',
                active: true,
              },
            ],
            title: 'Collaborateurs', 
            icon: 'icon-users', 
            tips: 'Liste des collaborateurs',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-collaborator',
          name: 'new-collaborator',
          component: () => import('@/views/directory/collaborator/EditCollaborator.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau collaborateur',
            breadcrumb: [
              {
                text: 'Collaborateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau collaborateur', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau collaborateur',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/edit-collaborator/:id',
          name: 'edit-collaborator',
          component: () => import('@/views/directory/collaborator/EditCollaborator.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche collaborateur',
            breadcrumb: [
              {
                text: 'Collaborateurs',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un collaborateur', 
            icon: 'icon-home', 
            tips: 'Editer un collaborateur',
            key: 'path', 
            closable: true 
          },
        },

        /* *** Gestion des Affaires *** */
         {
          path: '/affairs',
          name: 'affairs',
          component: () => import('@/views/directory/affair/Affairs.vue'),
          meta: {
            pageTitle: 'Affaires',
            breadcrumb: [
              {
                text: 'Affaires',
                active: true,
              },
            ],
            title: 'Affaires', 
            icon: 'icon-users', 
            tips: 'Liste des affaires',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/new-affair',
          name: 'new-affair',
          component: () => import('@/views/directory/affair/EditAffair.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouvelle affaire',
            breadcrumb: [
              {
                text: 'Affaires',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouvelle affaire', 
            icon: 'icon-home', 
            tips: 'Créer une nouvelle affaire',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/edit-affair/:id',
          name: 'edit-affair',
          component: () => import('@/views/directory/affair/EditAffair.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche affaire',
            breadcrumb: [
              {
                text: 'Affaires',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer une affaire', 
            icon: 'icon-home', 
            tips: 'Editer une affaire',
            key: 'path', 
            closable: true 
          },
        },

        /* *** Gestion des Entreprises *** */
        {
          path: '/directory/companies/:name/:id',
          name: 'companies',
          component: () => import('@/views/directory/company/Companies.vue'),
          meta: {
            pageTitle: 'Clients',
            breadcrumb: [
              {
                text: 'Clients',
                active: true,
              },
            ],
            title: 'Clients',
            icon: 'icon-home', 
            tips: 'Liste des clients',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/directory/new-company/:name/:companyTypeId',
          name: 'new-company',
          component: () => import('@/views/directory/company/EditCompany.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau client',
            breadcrumb: [
              {
                text: 'Clients',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau client', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau client',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/directory/edit-company/:id',
          name: 'edit-company',
          component: () => import('@/views/directory/company/EditCompany.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche client',
            breadcrumb: [
              {
                text: 'Clients',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un client', 
            icon: 'icon-home', 
            tips: 'Editer un client',
            key: 'path', 
            closable: true 
          },
        },

        /* *** Gestion des Contacts *** */
        {
          path: '/directory/contacts',
          name: 'contacts',
          component: () => import('@/views/directory/contact/Contacts.vue'),
          meta: {
            pageTitle: 'Contacts',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
            ],
            title: 'Contacts', 
            icon: 'icon-users', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des contacts',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/directory/new-contact',
          name: 'new-contact',
          component: () => import('@/views/directory/contact/EditContact.vue'),
          props: true,
          meta: {
            pageTitle: 'Nouveau contact',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Nouveau contact', 
            icon: 'icon-home', 
            tips: 'Créer un nouveau contact',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/directory/edit-contact/:id',
          name: 'edit-contact',
          component: () => import('@/views/directory/contact/EditContact.vue'),
          props: true,
          meta: {
            pageTitle: 'Fiche contact',
            breadcrumb: [
              {
                text: 'Contacts',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un contact', 
            icon: 'icon-home', 
            tips: 'Editer un contact',
            key: 'path', 
            closable: true 
          },
        },


        /* *** MODULE PLANNING *** */
        {
          path: '/planning/:id',
          name: 'planning',
          props: true,
          component: () => import('@/views/Bryntum.vue'),
          meta: {
            pageTitle: 'Planning',
            breadcrumb: [
              {
                text: 'Planning',
                active: true,
              },
            ],

            title: 'Planning', 
            icon: 'icon-home', 
            tips: 'Gestionnaire de planning',
            key: 'path', 
            closable: true 
          },
        },

        {
          path: '/company/settings/contracts',
          name: 'contracts',
          component: () => import('@/views/directory/contract/Contracts.vue'),
          meta: {
            pageTitle: 'Contrats',
            breadcrumb: [
              {
                text: 'Contrats',
                active: true,
              },
            ],
            title: 'Contrats', 
            icon: 'icon-home', 
            tips: 'Tableau de bord principal',
            key: 'path', 
            closable: true 
          },
        },

        {
          path: '/company/settings/jobs',
          name: 'jobs',
          component: () => import('@/views/directory/job/Jobs.vue'),
          meta: {
            pageTitle: 'Métiers',
            breadcrumb: [
              {
                text: 'Métiers',
                active: true,
              },
            ],
            title: 'Métiers', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste de métiers',
            key: 'path', 
            closable: true 
          },
        },

        /* *** ROUTES D'ADMINISTRATION GLOBALE */
        {
          path: '/admin/admin-users',
          name: 'adminUsers',
          component: () => import('@/views/account/users/AdminUsers.vue'),
          meta: {
            pageTitle: 'Gestion des utilisateurs',
            breadcrumb: [
              {
                text: 'Gestion des utilisateurs',
                active: true,
              },
            ],
            title: 'Utilisateurs', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Gestion des utilisateurs ORISIS',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/admin/admin-workspaces',
          name: 'adminWorkspaces',
          component: () => import('@/views/account/workspaces/AdminWorkspaces.vue'),
          meta: {
            pageTitle: 'Gestion des espaces de travail',
            breadcrumb: [
              {
                text: 'Gestion des espaces de travail',
                active: true,
              },
            ],
            title: 'Workspaces', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Gestion des bases clients',
            key: 'path', 
            closable: true 
          },
        },

        // GESTION DES PARAMETRES **PLANNING** 
        {
          path: '/planning/settings/event-types',
          name: 'eventTypes',
          component: () => import('@/views/planning/settings/EventTypes.vue'),
          meta: {
            pageTitle: 'Types d\'événement',
            breadcrumb: [
              {
                text: 'Types d\'événement',
                active: true,
              },
            ],
            title: 'Types d\'événement de planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des types d\'événements',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/planning/settings/planning-views',
          name: 'planningViews',
          component: () => import('@/views/planning/settings/PlanningViews.vue'), 
          meta: {
            pageTitle: 'Gestion des plannings',
            breadcrumb: [
              {
                text: 'Vues de planning',
                active: true,
              },
            ],
            title: 'Vues de planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Liste des vues de planning',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/planning/settings/new-planning-view',
          name: 'new-planning-view',
          component: () => import('@/views/planning/settings/EditPlanningView.vue'),
          props: true,
          meta: {
            pageTitle: 'Planning',
            breadcrumb: [
              {
                text: 'Plannings',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Créer un nouveau planning',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/planning/settings/edit-planning-view/:id',
          name: 'edit-planning-view',
          component: () => import('@/views/planning/settings/EditPlanningView.vue'),
          props: true,
          meta: {
            pageTitle: 'Planning : NOM',
            breadcrumb: [
              {
                text: 'Plannings',
                active: true,
              },
              {
                text: 'Editer',
                active: true,
              },
            ],
            title: 'Editer un planning', 
            icon: 'icon-home', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Editer un planning',
            key: 'path', 
            closable: true 
          },
        },



        {
          path: '/planning/settings/planning-views/create',
          name: 'planningViewsCreate',
          component: () => import('@/views/planning/planning-settings/planning-views/PlanningViewCreate.vue'),
          props: true,
          meta: {
            pageTitle: "Création d'une vue de planning",
            breadcrumb: [
              {
                text: "Création d'une vue de planning",
                active: true,
              },
            ],
            title: 'Créer une vue de planning', 
            icon: 'icon-edit', 
            tabClass: 'custom-tab', // custom class, optional
            tips: 'Créer une vue de planning',
            key: 'path', 
            closable: true 
          },
        },
        {
          path: '/planning/settings/planning-views/update',
          name: 'planningViewsUpdate',
          component: () => import('@/views/planning/planning-settings/planning-views/PlanningViewUpdate.vue'),
            meta: {
              pageTitle: "Modification d'une vue de planning",
              breadcrumb: [
                {
                  text: "Modification d'une vue de planning",
                  active: true,
                },
              ],
              title: 'Modifier une vue de planning', 
              icon: 'icon-edit', 
              tabClass: 'custom-tab', // custom class, optional
              tips: 'Créer une vue de planning',
              key: 'path', 
              closable: true 
            },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/register',
      name: 'register',
      component: () => import('@/views/Register.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },]
})
  

router.beforeEach((to, from, next) => {
  console.log(to, from)
  console.log("beforeEach", to)
  // Liste des URL disponibles sans authentification
  const publicPages = ['/login', '/register','/error-404'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = JSON.parse(localStorage.getItem('login'))
  if (authRequired && (loggedIn && loggedIn.login.userInfo==null)) {
    console.log("loggedIn", loggedIn)
    console.log("authRequired", authRequired)
    return next('/login');
  }else{
    // Si un titre est envoyé dans les paramètre, alors on personnalise les metas liés au router-tab
    console.log("to > ",to)
    if(to.params && to.params.title){
      to.meta.title = to.params.title
      // to.meta.pageTitle = to.params.pageTitle ? to.params.pageTitle : to.params.title
      to.meta.tips = to.params.tips ? to.params.tips : to.params.title
    }else if(to.params && to.params.name){
      if(to.path.includes('new-')){
        to.meta.title = 'Nouveau '+to.params.name
        to.meta.pageTitle = 'Nouveau '+to.params.name
        to.meta.tips = 'Créer un nouveau ' + to.params.name
      }else{
        to.meta.title = to.params.name[0].toUpperCase() + to.params.name.slice(1)
        to.meta.pageTitle = to.params.name[0].toUpperCase() + to.params.name.slice(1)
        to.meta.tips = 'Liste des ' + to.params.name + 's'
      }
    }
    next();
  }
})

router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
