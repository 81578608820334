import { IContractState } from "./states"
import { Module } from "vuex"
import { IContract } from "@/types/api-orisis/interfaces/Directory/IContract"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IContractState = {
    contractsList: Array<IContract>(),
    contract     : <IContract>{},
    isLoadingContractsList    : false,
    isLoadingContract    : false,
    isCreatingContract   : false,
    isUpdatingContract   : false,
    isDeletingContract   : false,
    requestStateContract : <IRequestState>{}
}

export const ContractModule: Module<IContractState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}