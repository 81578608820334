import { IAffairState } from "./states"
import { Module } from "vuex"
import { IAffair } from "@/types/api-orisis/interfaces/Directory/IAffair"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IAffairState = {
    affairsList         : Array<IAffair>(),
    affairsListArchived : Array<IAffair>(),
    affair              : <IAffair>{},
    isLoadingAffairsList     : false,
    isLoadingAffairsListArchived     : false,
    isLoadingAffair     : false,
    isCreatingAffair    : false,
    isUpdatingAffair    : false,
    isDeletingAffair    : false,
    isArchivingAffair    : false,
    requestStateAffair: <IRequestState>{},
    isOpenSidebarAffair : false,
}

export const AffairModule: Module<IAffairState, RootState> = {
    state, 
    getters,
    mutations,
    actions
} 