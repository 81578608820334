import { IJobState } from "./states"
import { Module } from "vuex"
import { IJob } from "@/types/api-orisis/interfaces/Directory/IJob"
import { getters } from "./getters"
import { mutations } from "./mutations"
import { actions } from "./actions"
import RootState from "@/store/modules/RootState"
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState"

const state:IJobState = {
    jobsList       : Array<IJob>(),
    job            : <IJob>{},
    isLoadingJobsList   : false,
    isLoadingJob   : false,
    isCreatingJob  : false,
    isUpdatingJob  : false,
    isDeletingJob  : false,
    requestStateJob: <IRequestState>{}
}

export const JobModule: Module<IJobState, RootState> = {
    state, 
    getters,
    mutations,
    actions
}