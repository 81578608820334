import { RootState } from '@/store/types'
import { IPlanningView } from '@/types/api-orisis/interfaces/Planning/IPlanningViews'
import axios from 'axios'
import { ActionTree } from 'vuex'
import { PlanningViewMutations } from './mutations'
import { PlanningViewState } from './states'
import { IPlanningLinesCollaborator } from '@/types/api-orisis/interfaces/Planning/PlanningLines'
import { IPlanningLine } from '@/types/api-orisis/interfaces/Planning/PlanningLine'
import router from '@/router'
import RouterTab from 'vue-router-tab'

export const actions: ActionTree<PlanningViewState, RootState> = {
  /**
   * It fetches all planning views by workspace id.
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {string} query - string
   */   
  async fetchPlanningViewsByWorkspaceId ({
    commit,
    rootGetters
  }): Promise<void> {
    /* Creating a new class called PlanningViewMutations. */
    commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEWS_LIST, true)
    const url = `${process.env.VUE_APP_API_ORISIS}Plannings/PlanningView/GetPlanningViewByWorkspace`
    await axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${rootGetters.userInfo.token}`
        },
        params: {
          workspaceId: rootGetters.workspaceSelected
        }
      })
      .then(res => {
        commit(
          PlanningViewMutations.SET_PLANNING_VIEWS_LIST,
          res.data as IPlanningView
        )
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEWS_LIST, false)
        return res.data as IPlanningView
      })
      .catch(err => {
        console.log('error =>', err)
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEWS_LIST, false)
        return false
      })
  },

  /**
   * Fetch the planning lines of the selected workspace
   * @param  - - **commit**: The commit function of the Vuex store.
   */
  async fetchPlanningLinesAffair ({ commit, rootGetters }) {
    var config = {
      headers: {
        Authorization: `Bearer ${rootGetters.userInfo.token}`
      },
      params: {
        workspaceId: rootGetters.workspaceSelected
      }
    }
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/GetPlanningLineAffair',
        config
      )
      .then(response => {
        let new_array = response.data.map(function (ele) {
          return { ...ele, type: 'affairLine', typeLine: 0 }
        })
        commit('SET_AFFAIR_LINES_OPTIONS', new_array)
      })
      .catch(error => {
        console.error(error)
      })
  },

  /**
   * Fetch the planning lines of the selected workspace
   * @param  - - **commit**: The commit function of the Vuex store.
   */
  async fetchPlanningLinesFreeLine ({ commit, rootGetters }) {
    var config = {
      headers: {
        Authorization: `Bearer ${rootGetters.userInfo.token}`
      },
      params: {
        workspaceId: rootGetters.workspaceSelected
      }
    }
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/GetPlanningLineFreeLine',
        config
      )
      .then(response => {
        let new_array = response.data.map(function (ele) {
          return { ...ele, type: 'freeLine', typeLine: 0 }
        })
        commit(PlanningViewMutations.SET_FREELINE_LINES_OPTIONS, new_array)
      })
      .catch(error => {
        console.error(error)
      })
  },
  /* The `fetchDetailPlanningView` function is called when we click on a planning view in the planning
 view list. */
  /* It fetches the planning view details. */
  async fetchDetailPlanningView (
    { commit, rootGetters },  id
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, true)

      console.log('rootGetters', rootGetters)
      if (id == 0) {
        let planningView: IPlanningView = {
          id:0,
          name: '',
          order: 0,
          isDefault: false,
          workspaceId: '',
          linePositions: [],
          usersShared: []
        }
        commit(PlanningViewMutations.SET_DETAIL_PLANNING_VIEW, planningView)
        commit(PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW, false)

        resolve(planningView)
      } else {
        axios
          .get(
            `${process.env.VUE_APP_API_ORISIS}Plannings/PlanningView/GetPlanningViewById`,
            {
              headers: {
                Authorization: `Bearer ${rootGetters.userInfo.token}`
              },
              params: {
                planningViewId: id as String
              }
            }
          )
          .then(res => {
            console.log("PlanningView res", res)
            console.log('action/fetchDetailPlanningView', res)
            commit(
              PlanningViewMutations.SET_DETAIL_PLANNING_VIEW,
              res.data as IPlanningView
            )
            commit(
              PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW,
              false
            )
            resolve(res.data as IPlanningView)
          })
          .catch(err => {
            console.error('error =>', err)
            commit(
              PlanningViewMutations.SET_IS_LOADING_PLANNING_VIEW,
              false
            )
            reject(err)
          })
      }
    })
  },

  /* A function that is called when we click on the "create planning view" button. */

  async storePlanningView (
    { commit, dispatch, rootGetters },
     planningViewPayload
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, true)
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Plannings/PlanningView/CreatePlanningView`,
          {...planningViewPayload, workspaceId: rootGetters.workspaceSelected},
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userInfo.token}`
            }
          }
        )
        .then(res => {
          //! Attention, les commit sont surement à revoir ! CF SAUVEGARDE
          if (planningViewPayload.id)
            commit(
              PlanningViewMutations.SAVE_UPDATED_PLANNING_VIEW,
              planningViewPayload
            )
          else
            commit(PlanningViewMutations.SET_PLANNING_VIEW, planningViewPayload)

          dispatch('fetchPlanningViewsByWorkspaceId')
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false)
          router.go(-1)
          /* A function that is called when we click on the "create planning view" button. */
          // RouterTab.open('/planning/settings/planning-views')
          // router.push()
          resolve(planningViewPayload)
        })
        .catch(err => {
          if (err.response) console.log('error =>', err)
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false)
          reject(err)
        })
    })
  },

  async updatePlanningView (
    { commit, dispatch, rootGetters },
    planningViewPayload
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, true)
      axios
        .put(
          `${process.env.VUE_APP_API_ORISIS}Plannings/PlanningView/UpdatePlanningView`,
          planningViewPayload,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userInfo.token}`
            }
          }
        )
        .then(res => {
          //! Attention, les commit sont surement à revoir ! CF SAUVEGARDE
          commit(
            PlanningViewMutations.SAVE_UPDATED_PLANNING_VIEW,
            planningViewPayload
          )
          console.log('ici un update ce passe', planningViewPayload)
          dispatch('fetchPlanningViewsByWorkspaceId')
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false)
          router.go(-1)
          /* A function that is called when we click on the "create planning view" button. */
          // RouterTab.open('/planning/settings/planning-views')
          // router.push()
          resolve(planningViewPayload)
        })
        .catch(err => {
          if (err.response) console.log('error =>', err)
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_VIEW, false)
          reject(err)
        })
    })
  },

  /* The `deletePlanningView` function is called when we click on the delete button of a planning view. */

  async deletePlanningView (
    { commit, rootGetters },
    { id, that }
  ): Promise<any> {
    commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, true)
    await axios
      .delete(
        `${process.env.VUE_APP_API_ORISIS}Plannings/PlanningView/Delete`,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            planningViewId: id as String
          }
        }
      )
      .then(res => {
        commit(PlanningViewMutations.SET_DELETE_PLANNING_VIEW, id)
        commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, false)
      })
      .catch(err => {
        console.log('error =>', err)
        commit(PlanningViewMutations.SET_IS_DELETING_PLANNING_VIEW, false)
      })
  },

  /**
   * Fetch the list of planning lines collaborator from the API
   * @param  - - **commit**: the commit function of the Vuex store.
   */
  async fetchPlanningLinesCollaborator ({ commit, rootGetters }): Promise<any> {
    var config = {
      headers: {
        Authorization: `Bearer ${rootGetters.userInfo.token}`
      },
      params: {
        workspaceId: rootGetters.workspaceSelected
      }
    }
    await axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Plannings/PlanningView/GetPlanningLineCollaborator',
        config
      )
      .then(response => {
        let new_array = response.data.map(function (ele) {
          return { ...ele, type: 'collaboratorLine', typeLine: 0 }
        })
        commit(
          PlanningViewMutations.SET_COLLAB_LINES_OPTIONS,
          new_array.filter(elem => elem.label !== null)
        )
      })
      .catch(error => {
        console.error(error)
      })
  },
  /**
   * It creates a new free planning line.
   * @param  - `{ commit, dispatch, rootGetters }`
   * @param  - `{ commit, dispatch, rootGetters }`
   */

  async storePlanningLine (
    { commit, dispatch, rootGetters },
    { planningLinePayload, that }
  ): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_LINE, true)
      console.log('Create planning View', planningLinePayload)
      axios
        .post(
          `${process.env.VUE_APP_API_ORISIS}Plannings/PlanningLine/CreateFreeLine`,
          planningLinePayload,
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userInfo.token}`
            }
          }
        )
        .then(res => {
          //! Attention, les commit sont surement à revoir ! CF SAUVEGARDE
          if (planningLinePayload.id) {
            commit(
              /* A mutation that is called when we click on the "create planning view" button. */
              PlanningViewMutations.SAVE_UPDATED_PLANNING_LINE,
              planningLinePayload
            )
          } else
            commit(PlanningViewMutations.SET_PLANNING_LINE, planningLinePayload)

          dispatch('fetchPlanningViewsByWorkspaceId')
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_LINE, false)
          console.log(' res.data.', res.data)
          rootGetters.planningLinesFreeLine.push()
          console.log(
            'planningLinesFreeLine',
            rootGetters.planningLinesFreeLine
          )

          commit(
            PlanningViewMutations.SET_FREELINE_LINES_OPTIONS,
            rootGetters.planningLinesFreeLine
          )
          resolve(planningLinePayload)
        })
        .catch(err => {
          if (err.response) console.log('error =>', err)
          commit(PlanningViewMutations.SET_IS_CREATING_PLANNING_LINE, false)
          reject(err)
        })
    })
  }
}
