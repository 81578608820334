import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ICollaborator } from "@/types/api-orisis/interfaces/Directory/ICollaborator";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import { ICollaboratorState } from "./states";

export const getters: GetterTree<ICollaboratorState, RootState> = {
    collaboratorsList: (state): ICollaborator[] => state.collaboratorsList,
    collaboratorsListArchived: (state): ICollaborator[] => state.collaboratorsListArchived,
    collaborator     : (state): ICollaborator => state.collaborator,
    
    // COMMON
    isLoadingCollaboratorsList   : (state): boolean => state.isLoadingCollaboratorsList,
    isLoadingCollaboratorsListArchived   : (state): boolean => state.isLoadingCollaboratorsListArchived,
    isLoadingCollaborator   : (state): boolean => state.isLoadingCollaborator,
    isCreatingCollaborator  : (state): boolean => state.isCreatingCollaborator,
    isUpdatingCollaborator  : (state): boolean => state.isUpdatingCollaborator,
    isDeletingCollaborator  : (state): boolean => state.isDeletingCollaborator,
    isArchivingCollaborator : (state): boolean => state.isArchivingCollaborator,
    requestStateCollaborator: (state): IRequestState => state.requestStateCollaborator,
    isOpenSidebarCollaborator: (state): boolean => state.isOpenSidebarCollaborator
}