import { ICompanyState } from "./states";
import { MutationTree } from "vuex";
import { ICompany } from "@/types/api-orisis/interfaces/Directory/ICompany";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";

export enum CompanyMutations{
    SET_COMPANIES_LIST = "SET_COMPANIES_LIST",
    SET_COMPANIES_LIST_ARCHIVED = "SET_COMPANIES_LIST_ARCHIVED",
    SET_COMPANY = "SET_COMPANY",
    
    // COMON
    SET_IS_LOADING_COMPANIES_LIST = "SET_IS_LOADING_COMPANIES_LIST",
    SET_IS_LOADING_COMPANIES_LIST_ARCHIVED = "SET_IS_LOADING_COMPANIES_LIST_ARCHIVED",
    
    SET_IS_LOADING_COMPANY = "SET_IS_LOADING",
    SET_IS_CREATING_COMPANY = "SET_IS_CREATING",
    SET_IS_UPDATING_COMPANY = "SET_IS_UPDATING",
    SET_IS_DELETING_COMPANY = "SET_IS_DELETING",
    SET_IS_ARCHIVING_COMPANY = "SET_IS_ARCHIVING_COMPANY",
    SET_REQUEST_STATE_COMPANY = "SET_REQUEST_STATE",
    SET_IS_OPEN_SIDEBAR_COMPANY = "SET_IS_OPEN_SIDEBAR_COMPANY"
  }

  export const mutations: MutationTree<ICompanyState> = {
    [CompanyMutations.SET_COMPANIES_LIST]: (state, payload: ICompany[]) => {
        state.companiesList = payload;
    },
    [CompanyMutations.SET_COMPANIES_LIST_ARCHIVED]: (state, payload: ICompany[]) => {
        state.companiesListArchived = payload;
    },
    [CompanyMutations.SET_COMPANY]: (state, payload: ICompany) => {
        state.company = payload;
    },


    //COMMON
    [CompanyMutations.SET_IS_LOADING_COMPANIES_LIST]: (state, payload: boolean) => {
        state.isLoadingCompaniesList = payload;
    },
    [CompanyMutations.SET_IS_LOADING_COMPANIES_LIST_ARCHIVED]: (state, payload: boolean) => {
        state.isLoadingCompaniesListArchived = payload;
    },
    [CompanyMutations.SET_IS_LOADING_COMPANY]: (state, payload: boolean) => {
        state.isLoadingCompany = payload;
    },
    [CompanyMutations.SET_IS_CREATING_COMPANY]: (state, payload: boolean) => {
        state.isCreatingCompany = payload;
    },
    [CompanyMutations.SET_IS_UPDATING_COMPANY]: (state, payload: boolean) => {
        state.isUpdatingCompany = payload;
    },
    [CompanyMutations.SET_IS_DELETING_COMPANY]: (state, payload: boolean) => {
        state.isDeletingCompany = payload;
    },
    [CompanyMutations.SET_REQUEST_STATE_COMPANY]: (state, payload: IRequestState) => {
        state.requestStateCompany = payload;
    },
    [CompanyMutations.SET_IS_OPEN_SIDEBAR_COMPANY]: (state, payload: boolean) => {
        state.isOpenSidebarCompany = payload;
    },
    [CompanyMutations.SET_IS_ARCHIVING_COMPANY]: (state, payload: boolean) => {
        state.isArchivingCompany = payload;
    }
}