import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";
import { ICollaborator } from "@/types/api-orisis/interfaces/Directory/ICollaborator";
import { CompanyType } from "@/../Api";
import { GetterTree } from "vuex";
import RootState from "../../RootState";
import {  ICompanyTypeState } from "./states";

export const getters: GetterTree<ICompanyTypeState, RootState> = {
    companiesTypesList: (state): CompanyType[] => state.companiesTypesList,
    companyType     : (state): CompanyType => state.companyType,
    
    // COMMON
    isLoadingCompanyType   : (state): boolean => state.isLoadingCompanyType,
    isCreatingCompanyType  : (state): boolean => state.isCreatingCompanyType,
    isUpdatingCompanyType  : (state): boolean => state.isUpdatingCompanyType,
    isDeletingCompanyType  : (state): boolean => state.isDeletingCompanyType,
    requestStateCompanyType: (state): IRequestState => state.requestStateCompanyType
}