<template>
 <router-tab :lang="customLanguage"  />
</template>

<script>
export default {
  data() {
    return {
      customLanguage: {
        tab: {
          untitled: 'Page'
        },

        contextmenu: {
          refresh: 'Actualiser l\'onglet',
          refreshAll: 'Actualiser tout',
          close: 'Fermer',
          closeLefts: 'Fermer les onglets à gauche',
          closeRights: 'Fermer les onglets à droite',
          closeOthers: 'Fermer tout'
        },

        msg: {
          keepLastTab: 'Keep at least 1 tab'
        }
      }
    }
  }
}
</script>
