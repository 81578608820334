// import { GET_ALL_PRODUCTS } from "./Types";
import axios from 'axios';

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

// initial state
const state = () => ({
  userInfo: null
})

// getters
const getters = {
  userInfo: state => state.userInfo
};

// actions
const actions = {

  //! /!\ FOR GOOGLE AUTH /!\ Using the oAuthInfo object to get the user's email, firstname, lastname and token.
  // async oAuthToken({
  //   commit,
  //   rootState
  // }, {
  //   that,
  //   oAuthInfo
  // }) {
  //   let userInfo = {
  //     email: "antoine.miloux@devinfluence.fr",
  //     firstname: "Antoine",
  //     lastname: "MILOUX",
  //     token: oAuthInfo.authResponse.login_hint,
  //     // workspaceIds: 
  //   }
  //   commit('setUserInfo', userInfo);
  //   that.$router.push("/");
  // },
//! ---------------------------


  async login({
    commit,
    dispatch,
    rootState
  }, {
    payload,
    that
  }) {

    var config = {
      method: "post",
      url: process.env.VUE_APP_API_ORISIS + "Account/Login",
      headers: {
        "Content-Type": "application/json",
      },
      data: payload,
    };
    axios(config)
      .then(function (response) {
        console.log("=============================", response.data)
        commit('setUserInfo', response.data)
        
        dispatch('setWorkspaceSelected', response.data.workspaceIds[0]);
        dispatch('fetchAllPlanningViewsByWorkspaceId');
        dispatch('fetchEventTypesList');
        dispatch('fetchPublicHolidaylist');
        dispatch('fetchAffairsList');
        dispatch('fetchCompaniesList');
        dispatch('fetchCompaniesListArchived');
        dispatch('fetchCompaniesTypesList');
        dispatch('fetchContactsList');
        dispatch('fetchContactsListArchived');
        dispatch('fetchCollaboratorsList');
        dispatch('fetchCollaboratorsListArchived');
        dispatch('fetchUsersList');
        dispatch('fetchUsersListArchived');
        dispatch('fetchWorkspacesList')
        
        that.$router.push("/");
      })
     


  },
  logout({commit, rootState}, that) {
    localStorage.clear();
  }

}


// mutations
const mutations = {
  setUserInfo: (state, userInfo) => {
    state.userInfo = userInfo;
  },
  SET_USER_INFO_TOKEN: (state, token) => {
    state.userInfo.token = token
  }

}

export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations
}
