import { IContractState } from "./states";
import { MutationTree } from "vuex";
import { IContract } from "@/types/api-orisis/interfaces/Directory/IContract";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum ContractMutations{
    SET_CONTRACTS_LIST = "SET_CONTRACTS_LIST",
    SET_CONTRACT = "SET_CONTRACT",
    
    // COMON
    SET_IS_LOADING_CONTRACTS_LIST= "SET_IS_LOADING_CONTRACTS_LIST",
    SET_IS_LOADING_CONTRACT= "SET_IS_LOADING_CONTRACT",
    SET_IS_CREATING_CONTRACT = "SET_IS_CREATING_CONTRACT",
    SET_IS_UPDATING_CONTRACT= "SET_IS_UPDATING_CONTRACT",
    SET_IS_DELETING_CONTRACT = "SET_IS_DELETING_CONTRACT",
    SET_REQUEST_STATE_CONTRACT = "SET_REQUEST_STATE_CONTRACT"
  }

  export const mutations: MutationTree<IContractState> = {
    [ContractMutations.SET_CONTRACTS_LIST]: (state, payload: IContract[]) => {
        state.contractsList = payload;
    },
    [ContractMutations.SET_CONTRACT]: (state, payload: IContract) => {
        state.contract = payload;
    },


    //COMMON
    [ContractMutations.SET_IS_LOADING_CONTRACTS_LIST]: (state, payload: boolean) => {
        state.isLoadingContractsList = payload;
    },
    [ContractMutations.SET_IS_LOADING_CONTRACT]: (state, payload: boolean) => {
        state.isLoadingContract = payload;
    },
    [ContractMutations.SET_IS_CREATING_CONTRACT]: (state, payload: boolean) => {
        state.isCreatingContract = payload;
    },
    [ContractMutations.SET_IS_UPDATING_CONTRACT]: (state, payload: boolean) => {
        state.isUpdatingContract = payload;
    },
    [ContractMutations.SET_IS_DELETING_CONTRACT]: (state, payload: boolean) => {
        state.isDeletingContract = payload;
    },
    [ContractMutations.SET_REQUEST_STATE_CONTRACT]: (state, payload: IRequestState) => {
        state.requestStateContract = payload;
    },
}