import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App'

import 'material-icons/iconfont/material-icons.css' //Material Icons

import RouterTab from 'vue-router-tab'
import 'vue-router-tab/dist/lib/vue-router-tab.css'

import vuetify from './plugins/vuetify.js';
// Global Components
import './global-components'
import axios from "axios"
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import VTooltip from 'v-tooltip'
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
Vue.use(Vuesax, {
  // options here
})
Vue.use(VTooltip, {
  themes: {
    orisis: {
      $extend: 'dropdown',
      triggers: ['hover'],
      autoHide: true,
      eagerMount: true,
      placement: 'bottom',
      strategy: 'fixed',
      instantMove: true,
      handleResize: true,
      html: true
    }
  }
})
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')
// import 'v-tooltip/dist/v-tooltip.css'
import BootstrapVue from 'bootstrap-vue'
import VPopover from 'vue-js-popover'
Vue.use(VPopover, { tooltip: true })
Vue.use(BootstrapVue)
Vue.config.productionTip = false
Vue.use(RouterTab)
import {store} from 'vuex'

import 'ag-grid-enterprise';

import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import 'bootstrap/scss/bootstrap.scss';
Vue.component('vue-typeahead-bootstrap', VueTypeaheadBootstrap)

// Intercept Axios Request
axios.interceptors.request.use(function (config) {
  const loggedIn = JSON.parse(localStorage.getItem('login'))
  console.log("interceptors request", config, loggedIn)

  if((!config.url?.includes("Account/Login") && !config.url?.includes("Account/Register")) && (loggedIn && loggedIn.login.userInfo==null)){
    console.log("interceptors FALSE")
    return false;
  }else{
    return config;
  }
}, function (error) {
  return Promise.reject(error);
});


// Intercept Axios Response
axios.interceptors.response.use(
  response => response,
  error => {

    if (error.response.data.message) {
      vm.$bvToast.toast(error.response.data.message, {
        title: `Erreur ${error.response.status || ''}`,
        variant: "danger",
        solid: true,
      })
      console.log(error.response.status, error.response.data.message);
    }
    else if(error.response.status === 401) {
      vm.$bvToast.toast("Merci de vous reconnecter avec vos identifiants ORISIS.", {
        title: `Erreur d'authentification`,
        variant: "danger",
        solid: true,
      })
      localStorage.clear();
      store.dispatch('logout')
      router.push('/login')
    }
    else if(error.response.status === 404) {
      vm.$bvToast.toast("Une erreur s'est produite, merci de contacter le support ORISIS.", {
        title: `Erreur d'accès`,
        variant: "danger",
        solid: true,
      })
    }
    else{
      vm.$bvToast.toast("Une erreur s'est produite, merci de contacter le support ORISIS.", {
        title: `Erreur système`,
        variant: "danger",
        solid: true,
      })
    }
    return Promise.reject(error)
  }
)




// axios.interceptors.response.use(
//   function (response) {
//     // n’importe quel code de réponse HTTP dans la plage 2xx activera cette
//     // fonction
//     // faire quelque chose avec les données de la réponse

//     return response
//   },
//   function (error) {
//     // store.dispatch('logout')
//     // router.push("/login");


//     console.log(error.code)
//     vm.$toast({
//       component: ToastificationContent,
//       props: {
//         title: "Erreur inconnue !",
//         icon: "AlertTriangleIcon",
//         variant: "danger",
//       },
//     });
    


//     return Promise.reject(error)
//   }
// )




// import GoogleAuth from '@/oAuth/google.js'
// const gauthOption = {
//   clientId: '893185866337-a1618s9gs49l7r8l88onpvlb10alpho8.apps.googleusercontent.com',
//   scope: 'profile email',
//   prompt: 'select_account'
// }



Vue.prototype.$scheduler = {};
export let vm = new Vue({
  el: '#app',
  vuetify,
  router,
  store,
  render: h => h(App)
}).$mount('#app')
