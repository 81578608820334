import { IJob } from '@/types/api-orisis/interfaces/Directory/IJob'
import axios from 'axios'
import { ActionTree } from 'vuex'
import RootState from '../../RootState'
import { JobMutations } from './mutations'
import { IJobState } from './states'

export const actions: ActionTree<IJobState, RootState> = {
  /**
   * It fetches the list of jobs from the API and stores it in the state.
   */
  async fetchJobsList ({ commit, rootGetters }): Promise<void> {
    commit(JobMutations.SET_IS_LOADING_JOBS_LIST, true)
    axios
      .get(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Job/GetJobByWorkspaceId',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            workspaceId: rootGetters.workspaceSelected
          }
        }
      )
      .then(res => {
        commit(JobMutations.SET_JOBS_LIST, res.data)
        commit(JobMutations.SESET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_LOADING_JOBS_LIST, false)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SESET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_LOADING_JOBS_LIST, false)
      })
  },

  /**
   * Fetch a job from the API
   * @param  - jobId: The id of the job you want to fetch.
   * @param {string} jobId - The id of the job you want to fetch.
   */
  async fetchJob (
    { commit, rootGetters },
    jobId: string
  ): Promise<void> {
    return new Promise<void>((resolve, reject) => {
     if(jobId == ''){
       let job: IJob = {
         Label: '',
         WorkspaceId: rootGetters.workspaceSelected,
       }
      resolve(job)
     }else{
      commit(JobMutations.SET_IS_LOADING_JOB, true)
      axios
        .get(
          process.env.VUE_APP_API_ORISIS +
            'Parameters/Job/GetJobById',
          {
            headers: {
              Authorization: `Bearer ${rootGetters.userInfo.token}`
            },
            params: {
              jobId: jobId
            }
          }
        )
        .then(res => {
          commit(JobMutations.SET_JOB, res.data)
          commit(JobMutations.SESET_REQUEST_STATE_JOB, res)
          commit(JobMutations.SET_IS_LOADING_JOB, false)
          resolve(res.data)
        })
        .catch(err => {
          console.error(err)
          commit(JobMutations.SESET_REQUEST_STATE_JOB, err)
          commit(JobMutations.SET_IS_LOADING_JOB, false)
        })
     }
    })
  },

  /**
   * Create a job and add it to the jobs list
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IJob} job - The job object that will be sent to the API.
   */
  async createJob (
    { commit, rootGetters },
    job: IJob
  ): Promise<void> {
    commit(JobMutations.SET_IS_CREATING_JOB, true)
    axios
      .post(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Job/CreateJob',
          job,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        rootGetters.jobsList.unshift(res.data.data)
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SESET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_CREATING_JOB, false)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SESET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_CREATING_JOB, false)
      })
  },

  /**
   * Update a job
   * @param  - - `commit`: The commit function of the Vuex store.
   * @param {IJob} job - The job object that will be updated.
   */
  async updateJob (
    { commit, rootGetters },
    job: IJob
  ): Promise<void> {
    commit(JobMutations.SET_IS_UPDATING_JOB, true)
    axios
      .put(
        process.env.VUE_APP_API_ORISIS +
          'Parameters/Job/UpdateJob',
          job,
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          }
        }
      )
      .then(res => {
        rootGetters.jobsList.splice(
          rootGetters.jobsList.findIndex(
            elem => elem.id == job.id
          ),
          1,
          job
        )
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SESET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_UPDATING_JOB, false)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SESET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_UPDATING_JOB, false)
      })
  },

  /**
   * Delete a job from the database
   * @param  - - `commit`: the commit function of the Vuex store.
   * @param {string} jobId - The id of the job to delete.
   */
  async deleteJob (
    { commit, rootGetters },
    jobId: string
  ): Promise<void> {
    commit(JobMutations.SET_IS_DELETING_JOB, true)
    axios
      .delete(
        process.env.VUE_APP_API_ORISIS + 'Parameters/Job/Delete',
        {
          headers: {
            Authorization: `Bearer ${rootGetters.userInfo.token}`
          },
          params: {
            jobId: jobId
          }
        }
      )
      .then(res => {
        rootGetters.jobsList.splice(
          rootGetters.jobsList.findIndex(
            elem => elem.id == jobId
          ),
          1
        )
        commit(
          JobMutations.SET_JOBS_LIST,
          rootGetters.jobsList
        )
        commit(JobMutations.SESET_REQUEST_STATE_JOB, res)
        commit(JobMutations.SET_IS_DELETING_JOB, false)
      })
      .catch(err => {
        console.error(err)
        commit(JobMutations.SESET_REQUEST_STATE_JOB, err)
        commit(JobMutations.SET_IS_DELETING_JOB, false)
      })
  }
}
