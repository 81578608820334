import { ISmsState } from "./states";
import { MutationTree } from "vuex";
import { IRequestState } from "@/types/api-orisis/interfaces/Common/IRequestState";


export enum SmsMutations{
    SET_IS_LOADING_SMS= "SET_IS_LOADING_SMS",
    SET_REQUEST_STATE_SMS = "SET_REQUEST_STATE_SMS"
  }

  export const mutations: MutationTree<ISmsState> = {
    
    [SmsMutations.SET_IS_LOADING_SMS]: (state, payload: boolean) => {
        state.isLoadingSms = payload;
    },
    [SmsMutations.SET_REQUEST_STATE_SMS]: (state, payload: IRequestState) => {
        state.requestStateSms = payload;
    },
}