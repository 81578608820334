import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { PlanningViewModule } from './modules/planning/planningView/index';
import app from './app';
import appConfig from './app-config';
import verticalMenu from './vertical-menu';
import auth from "./modules/auth";
import createPersistedState from 'vuex-persistedstate';
import register from "./modules/account/Register";
import login from "./modules/account/Login.js";


import { EventTypeModule } from "./modules/planning/eventType/index"
import { SmsModule } from "./modules/planning/sms/index"

import { WorkspaceModule } from "./modules/account/workspace/index"; // fait mais il reste une fonction à placer au bon endroit
import { UserModule } from "./modules/directory/user/index";

import { AffairModule } from "./modules/directory/affair/index";
import { CollaboratorModule } from "./modules/directory/collaborator/index";
import { CompanyModule } from "./modules/directory/company/index";
import { CompaniesTypesModule } from "./modules/directory/companiesTypes/index";
import { ContactModule } from "./modules/directory/contact/index";
import { ContractModule } from "./modules/directory/contract/index";
import { JobModule } from "./modules/directory/job/index";
import { CommonModule } from "./modules/directory/common";

Vue.use(Vuex);
const authState = createPersistedState({ paths: ['login'], key: "login" });
const workspaceState = createPersistedState({ paths: ['WorkspaceModule.workspaceSelected'], key: "workspaceSelected" });
const planningViewState = createPersistedState({ paths: ['planningViewSelected'], key: "planningViewSelected" });

const store: StoreOptions<{}> = {
    modules: {
        //app de base 
        app,
        appConfig,
        verticalMenu,
        auth,
        //-----------

        // a refaire 
        login,
        register,
        PlanningViewModule,
        //-----------
        
        // OK
        SmsModule,
        WorkspaceModule, 
        CommonModule,
        EventTypeModule,
        UserModule,
        AffairModule,
        CollaboratorModule,
        CompanyModule,
        CompaniesTypesModule,
        ContactModule,
        ContractModule,
        JobModule,
        
        //-----------

    },
    plugins: [authState, workspaceState, planningViewState],

}

export default new Vuex.Store<{}>(store);
